._banner .list-banner li.item-banner-pagseguro {
  background-image: url("./images/banner-pagseguro.jpg");
}
._banner .list-banner li.item-banner-olist {
  background-image: url("./images/banner-olist.jpg");
}
._banner .list-banner li.item-banner-acelerai {
  background-image: url("./images/banner-acelerai.jpg");
}
._banner .list-banner li.item-banner-egestor {
  background-image: url("./images/banner-egestor.jpg");
}
._banner .list-banner li.item-banner-oiseguranca {
  background-image: url("./images/banner-oi-seguranca.jpg");
}
._banner .list-banner li.item-banner-bf {
  background-image: url("./images/banner-bf.jpg");
}

._banner ul.list-banner li.item-banner-bf {
  background-color: hsla(0, 0%, 93%, 1);
  background-size: contain;
  background-position: center;
}

._banner ul.list-banner li.item-banner-bf::before {
  content: none;
}

@media screen and (max-width: 812px) {
  ._banner .list-banner li.item-banner-pagseguro {
    background-image: url("./images/banner-pagseguro-mobile.jpeg");
  }
  ._banner .list-banner li.item-banner-olist {
    background-image: url("./images/banner-olist-responsivo.jpg");
  }
  ._banner .list-banner li.item-banner-acelerai {
    background-image: url("./images/banner-acelerai-responsivo.jpg");
  }
  ._banner .list-banner li.item-banner-egestor {
    background-image: url("./images/banner-egestor-responsivo.jpg");
  }
  ._banner .list-banner li.item-banner-oiseguranca {
    background-image: url("./images/banner-oi-seguranca-responsivo.jpg");
  }
  ._banner .list-banner li.item-banner-bf {
    background-image: url("./images/banner-bf-mobile.jpg");
  }
  ._banner ul.list-banner li.item-banner-bf {
    background-color: hsla(0, 0%, 93%, 1);
    background-size: cover;
    background-position: right;
  }

  ._banner ul.list-banner li {
    min-height: 400px;
  }
  ._banner ul.list-banner li img {
    margin-bottom: 16px;
    max-width: 170px;
  }
}
