.Collapsible__trigger {
    cursor: pointer;
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: black;
    position: relative;
    padding: 1rem;
    background: hsla(0, 0%, 0%, .04);
    margin-top: 1em;
    font-size: 18px;
}

.Collapsible__contentInner {
    padding: 1rem;
    border-top: 0;
}